//
//
//
//
//
//
//

export default {
    name: 'Logo',
    props: {
        fill: {
            required: false,
            default: '#000',
            type: String
        },
        width: {
            required: false,
            default: '48',
            type: String
        },

    }
}
