//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginIcon from './icons/LoginIcon';
import CartIcon from './icons/CartIcon';
import Backdrop from "./Backdrop";
export default {
  components: {
    Backdrop,
    CartIcon,
    LoginIcon
  },
  computed: {
    open(){
      return this.$store.state.menuOpen;
    },
    cartEmpty(){
      return this.$store.state.cart.empty;
    },
    isHome(){
      return this.$route.path === this.localePath('/');
    },
  },
  methods: {
    closeMenuHandler(){
      this.$store.dispatch('closeMenu');
    },
    brandsHandler(){

      if (this.isHome){
        console.log('isHOme')
        this.closeMenuHandler();
        this.$root.$emit('home-brands');
      } else {
        this.$router.push({
          path: this.localePath('/'),
          query: {section : "home-brands"}
        });

      }
    }

  },
  watch: {
    $route(to, from){
      this.closeMenuHandler();

    }
  }
}
