//
//
//
//
//
//
//
//

export default {
  props: {
    zIndex: {
      type: Number,
      default: 100,
      required: false,
    }
  },
  destroyed() {
    document.documentElement.classList.remove('backdrop-open');
  },
  mounted(){
    if (process.client){
      document.documentElement.classList.add('backdrop-open');
    }
  }
}
