import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=26f92359&scoped=true&"
import script from "./SideMenu.vue?vue&type=script&lang=js&"
export * from "./SideMenu.vue?vue&type=script&lang=js&"
import style0 from "./SideMenu.vue?vue&type=style&index=0&id=26f92359&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f92359",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSelector: require('/var/www/sites/antivirus.codes/components/LanguageSelector.vue').default,Backdrop: require('/var/www/sites/antivirus.codes/components/Backdrop.vue').default})
