//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


  export default {
    head() {
      const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

      return {
        link: [
          ...i18nHead.link
        ]
      }
    },
    computed:{
      menuOpen(){
        return this.$store.state.menuOpen;
      },
      cookieDisclaimer(){
				return this.$store.state.showCookieDisclaimer;
			},
      cookieConfiguration(){
        return this.$store.state.showCookieConfiguration;
      },
      languages(){
        return this.$store.state.languages;
      },
      routePath() {
        return this.$route.path;
      }
    },
    data(){
      return {
        cookieName: 'avc',
				allowGtmCookie: "allow-gtm"
      }
    },

    created(){
      if(process.client){
        if(this.$customization.hide_menu){
          document.body.classList.add('no_header')
        }
      }

    },
    mounted(){
      let cookie = this.$cookies.get(this.cookieName);
      if(cookie){
        this.$store.dispatch('setCookieDisclaimer', false);
        //cookie === this.allowGtmCookie ? this.$gtm.init() : null;
			} else if (!cookie) {
        //this.$gtm.init();
        setTimeout(() => {
          this.$store.dispatch('setCookieDisclaimer', true);
        }, 5000);
			}
    },
    watch: {
      $route(to, from){
        //this.$router.replace(to.path); // Esto permite la navegación
      }
    }
  }
