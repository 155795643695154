//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '../components/icons/Logo';
import LoginIcon from '../components/icons/LoginIcon';
import CartIcon from '../components/icons/CartIcon';
import MenuIcon from '../components/icons/MenuIcon';
export default {
  components: {
    LoginIcon,
    CartIcon,
    MenuIcon,
    Logo
  },
  data(){
    return {
      initialPos: 0
    }
  },
  computed: {
    isHome(){
      return this.$route.path === '/';
    },
    isCart(){
      return this.$route.name.indexOf('cart') !== -1;
    },
    cartEmpty(){
      return this.$store.state.cart.empty;
    },
    loggedUser(){
      return this.$auth.user;
    },
    menuOpen(){
      return this.$store.state.menuOpen;
    },
    appHeaderTitle(){
      if(this.isHome){
        return 'Mejores antivirus online';
      } else {
        return this.$store.state.navTitle;
      }
    }

  },
  methods: {
    toggleMenuHandler(){
      this.$store.dispatch('toggleMenu');
    },
  }
}
