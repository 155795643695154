//
//
//
//
//
//
//

export default {
    props: {
        stroke: {
            required: false,
            default: '#000',
            type: String
        },
        width: {
            required: false,
            default: '48',
            type: String
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean
        }
    }
}
